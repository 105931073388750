import React from 'react'

import PerpetualLicenseFWPage from 'components/perpetual-license'
import { JAPANESE } from 'utils/supportedLanguages'

const Redeem = () => {
  return (
    <PerpetualLicenseFWPage
      redeemPath={'/sourcenext/redeem/'}
      vendorSourceName={'SOURCENEXT'}
      queryParamKey={'sourcenext-code'}
      vendorName={'Sourcenext'}
      language={JAPANESE}
    />
  )
}

export default Redeem
